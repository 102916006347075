<template>
  <VisualizerComponent />
</template>

<script>
import VisualizerComponent from './components/VisualizerComponent.vue'

export default {
  name: 'App',
  components: {
    VisualizerComponent
  }
}
</script>

<style scoped>
</style>